<template>
  <v-card>
    <v-card-title
      >Bônus Pago nos Anos {{ filters.ano_ref - 1 }}/{{
        filters.ano_ref
      }}</v-card-title
    >
    <v-card-text>
      <Chart :chartOptions="chartOptions" />
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "@/components/shared/BaseChartTeste";
import campanha from "@/services/http/campanhaService";

export default {
  props: {
    categories: {},
    series: {},
    filters: {}
  },
  components: {
    Chart
  },
  data() {
    return {
      years: [],
      chartOptions: {
        chart: {
          type: "column",
          height: 388
        },
        colors: ["#1D3B5F", "#4AAC45"],
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        xAxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
          ]
        },
        yAxis: {
          title: {
            text: "Valor Despesa"
          }
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>R$ {point.y},00</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: false
          }
        },
        series: [],
        lang: {
          noData: "Não há dados para serem apresentados"
        },
        noData: {
          style: {
            fontWeight: "normal",
            fontSize: "1rem",
            color: "rgba(0, 0, 0, 0.6)"
          }
        }
      }
    };
  },

  watch: {
    filters(value) {
      this.fetchApuracaoCalculo(value);
    }
  },

  methods: {
    async fetchApuracaoCalculo(filter) {
      this.years = [filter.ano_ref - 1, filter.ano_ref];
      this.chartOptions.series = [];
      for (let year of this.years) {
        if (year > 2020) {
          let month = [];
          let res = [];
          let {
            data: { data }
          } = await campanha()
            .resultadoRealizadoCampanha()
            .show({
              per_page: -1,
              ano_ref: year,
              id_band: filter.id_band,
              id_empresa: filter.id_empresa
            });
          for (let j = 1; j <= 12; j++) {
            month.push(data.filter(item => item.mes_ref == j.toString()));
          }

          month.map((item, k) => {
            let counter = 0;
            item.map(subitem => {
              counter += parseFloat(subitem.resultado) || 0;
            });
            res[k] = counter;
          });

          this.chartOptions.series.push({
            name: year,
            data: res
          });
        }
      }
    }
  },

  mounted() {
    if (this.filters.ano_ref == undefined) {
      const date = new Date().toISOString().substr(0, 7);
      const [year] = date.split("-");
      this.fetchApuracaoCalculo(year);
    } else {
      this.fetchApuracaoCalculo(this.filters);
    }
  }
};
</script>

<style></style>
